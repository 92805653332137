/* eslint-disable no-debugger */
import moment, { Moment } from "moment"

export const formatText = (text: string, maxLength: number): string => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength).concat("...")
  }

  return text
}

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export const dateFromUtcToLocalFr = (date: string): Moment => {
  return moment.utc(date)
}

export const dateFromUtcToLocal = (date: string): Moment => {
  return moment.utc(date).local()
}

export const getDateToUtc = (date: string | Date | undefined) => {
  return new Date(date + "Z")
}

export const dateUtcToLocalFrFormatted = (
  date: string,
  format: string,
): string => {
  return dateFromUtcToLocalFr(date).format(format)
}

export const dateUtcToLocalFormatted = (
  date: string,
  format: string,
): string => {
  return dateFromUtcToLocal(date).format(format)
}

export const formatDate = (
  date: string | Date | DateConstructor,
  format: string,
) => {
  return moment(date as string).format(format)
}

export const getStringYearFromDate = (
  date: Date | string | undefined | null,
): string | null => {
  if (!date) return null
  return moment(date).format("YYYY")
}

export const formatIBAN = (value?: string) => {
  if (value)
    return (
      value.substring(0, 2) +
      "  " +
      value.substring(2, 4) +
      "  " +
      value.substring(4, 9) +
      "  " +
      value.substring(9, 14) +
      "  " +
      value.substring(14, 25) +
      "  " +
      value.substring(25, 27)
    ).trim()

  return ""
}

export const formatSecurityNumber = (value?: string) => {
  if (value)
    return (
      value.substring(0, 1) +
      "  " +
      value.substring(1, 3) +
      "  " +
      value.substring(3, 5) +
      "  " +
      value.substring(5, 7) +
      "  " +
      value.substring(7, 10) +
      "  " +
      value.substring(10, 13) +
      "  " +
      value.substring(13, 15)
    ).trim()

  return ""
}

export const formatTEL = (value?: string) => {
  const onlyNumbers: string = replaceAll(value ?? "", " ", "")
  const chunks: Array<string> = []
  if (onlyNumbers) {
    for (let i = 0, charsLength = onlyNumbers.length; i < charsLength; i += 2) {
      chunks.push(onlyNumbers.substring(i, i + 2))
    }
    return chunks.join(" ")
  }
  // if (onlyNumbers)
  //   return (
  //     onlyNumbers.substring(0, 2) +
  //     "  " +
  //     onlyNumbers.substring(2, 5) +
  //     "  " +
  //     onlyNumbers.substring(4, 6) +
  //     "  " +
  //     onlyNumbers.substring(6, 8) +
  //     "  " +
  //     onlyNumbers.substring(8, 10) +
  //     "  " +
  //     onlyNumbers.substring(10, 12)
  //   )

  return ""
}

export const getDefaultNullableBoolean = (
  value: boolean | undefined | null,
): string => {
  return value === true ? "Oui" : value === false ? "Non" : "-"
}

export const getDefaultBoolean = (value: boolean): string => {
  return value === true ? "Oui" : "Non"
}

export const formatTextValue = (value?: string | number): string => {
  if (!value || value === "") return "-"
  return value.toString()
}

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, "g"), replace)
}
